<!--
 * @Author: Akko
 * @Description: 完成(专才)
-->
<template>
    <div>
        <div class="done-box">
            <p class="done-tit">资料已全部完成填写</p>
            <p class="score-des">已提交的信息可通过：【个人中心—个人信息表】查看</p>
        </div>
        <!-- 保存按钮 -->
        <div class="operations-group">
            <el-button :loading="isLoading" class="person-btn" @click="goPre">上一步</el-button>
            <el-button :loading="isLoading" class="person-btn btn-blue" @click="done">确认提交</el-button>
        </div>
    </div>
</template>

<script lang="javascript">
import { userSubmit } from "@/api/workfow.js";
export default {
    name: "Done",
    components: {},
    data() {
        return {
            isLoading: false,
        };
    },
    created() {},
    methods: {
        // 完成并提交
        done() {
            let that = this;
            this.$confirm("提交后您将无法编辑，请再次确认", "确认提交信息？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.isLoading = true
                    userSubmit({
                        orderId: this.$route.params.id,
                    }).then((res) => {
                        if (res.code === 200) {
                            that.$message.success("提交成功！");
                            localStorage.setItem("stepEnd", 1);
                            localStorage.setItem("isList", 0);
                            that.$store.commit("user");
                            that.$router.replace({
                                name: "JumpPage",
                            });
                        }
                    }).finally(() => {
                        this.isLoading = false
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        // 返回上一步
        goPre() {
            this.$router.replace({
                name: "etep6",
            });
        },
    },
};
</script>

<style lang="less" scoped>
.done-box {
    width: 960px;
    margin: 108px auto 0;
    font-size: 14px;
    color: #303133;
    height: 300px;

    > p {
        margin-bottom: 30px;
    }
    .done-tit {
        text-align: center;
        font-size: 28px;
    }
}

.score-des {
    text-align: center;
    color: #606266;
    margin-bottom: 24px;
}

.operations-group {
    justify-content: center;
}
</style>
